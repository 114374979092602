@tailwind base;
@tailwind components;
@tailwind utilities;
:root{
  --max-screen-width: 500px;
}
/* 或者仅针对具有 tabindex 的元素 */
[tabindex]:focus {
  outline: none;
}
:root:root{
  --adm-color-primary: var(--1);
  --adm-color-background: var(--BG);
  --adm-mask-z-index: 10000;
  --adm-popup-z-index: 10010;
}

:root .adm-button{
  --adm-button-border-width: 0;
}

:root .adm-swiper{
  .adm-page-indicator{
    --dot-size: .25rem;
    --active-dot-size: 1.75rem;
    --dot-border-radius: .25rem;
    --dot-spacing: .5rem;
  }
}

:root .adm-image{
  --adm-color-fill-content: var(--image);

  img[src=""], img:not([src]){
    background: var(--image);
  }
}

:root .adm-center-popup{
  --adm-center-popup-min-width: 0;
  --adm-center-popup-max-width: 100vw;
}

:root .adm-toast-main{
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 18.5rem;
  width: fit-content;
  min-width: 0;
  .adm-toast-icon{
    margin-bottom: 0;
  }
}

.toast-mask{
  background: rgba(0, 0, 0, 0.3)!important;
}

.bg-linear-filter{
  background: var(--linear-filter);
}

html,
body {
  height: 100%;
  width: 100%;
  background: var(--BG);
  /* 文本不好默认，最好自行覆盖相应的文字颜色 */
  color: var(--T1);
  max-width: var(--max-screen-width);
  margin: 0 auto;
  font-weight: 500;
  font-family: 'Inter';
}
html{
  font-size: calc(16vw / 3.75);
}

@media (min-width: 500px) {
  html{
    font-size: calc(16 / 375 * var(--max-screen-width));
  }
}

:root{
  --sports--1: #E61514;
}
textarea:focus-visible {
  outline:#1D9D00 auto 1px;
}
.input-caret {
  position: relative;
}
.input-caret::before {
  content: '';
  width: 2px;
  height: 1em;
  background: rgba(29, 157, 0,1);
  animation: 1s linear infinite adm-caret-blink;
  position: absolute;
  top: 50%;
  right: -0.1em;
  transform: translateY(-50%);
}
@keyframes adm-caret-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

:root .base-tabs{
  --active-line-height: .25rem;
  --active-line-color: var(--1);
  --active-title-color: var(--T1);
  --title-font-size: 1rem;
  --fixed-active-line-width: 1.25rem;
  --active-line-border-radius: 0;
  background: transparent;
  .adm-tabs-header {
    border-bottom: none;
  }
  .adm-tabs-tab-list{
    gap: 3.75rem;
  }
  .adm-tabs-tab-wrapper{
    padding: 0;
  }

  .adm-tabs-tab{
    padding: 0;
  }

  .adm-tabs-tab-list{
    overflow: visible;
  }

  .adm-tabs-tab-line{
    bottom: -.25rem;
  }

  .adm-tabs-tab-active{
    font-weight: bold;
  }
}

:root .adm-badge-dot{
  border: 0.0625rem solid white;
  border-radius: 50%;
  width: 0.75rem;
  height: 0.75rem;
  filter: drop-shadow(0px 4.8px 4.8px rgba(0, 0, 0, 0.25));
}
